<template>
  <b-modal
    ref="commonModal"
    :title="title"
    :size="size"
    button-size="sm"
    footer-class="format-footer"
    header-class="text-gray-black"
    cancel-variant="danger"
    :ok-title="$slots.default ? titleOk : 'Đồng ý'"
    cancel-title="Đóng"
    no-close-on-backdrop
    @ok="onHandleOk"
    @cancel="resetForm"
  >
    <validation-observer
      ref="commonRules"
    >
      <slot />
    </validation-observer>
    <span v-if="!$slots.default">{{ content }}</span>
    <template slot="modal-footer">
      <slot name="footer" />
    </template>
  </b-modal>
</template>
<script>
import {
  BModal,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'lg',
    },
    titleOk: {
      type: String,
      default: 'Lưu',
    },
    content: {
      type: String,
      default: 'Bạn có chắc chắn muốn xóa không?',
    },
  },
  data() {
    return {
    }
  },
  methods: {
    showModal() {
      this.$refs.commonModal.show()
    },
    hideModal() {
      this.$refs.commonModal.hide()
      this.resetForm()
    },
    onHandleOk(e) {
      e.preventDefault()
      this.$refs.commonRules.validate().then(success => {
        if (success) {
          this.$emit('handle-ok')
          this.$refs.commonRules.reset()
          this.hideModal()
        }
      })
    },
    resetForm() {
      this.$emit('reset-form')
    },
  },
}
</script>
