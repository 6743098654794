<template>
  <div
    :key="formKey"
    class="card-body"
  >
    <div
      v-for="(toKhai, index) in danhSachToKhai"
      :key="toKhai.name"
    >
      <div
        v-b-toggle="toKhai.name"
        class="template-name"
        @click="(danhSachToKhai[index].active = !danhSachToKhai[index].active)"
      >
        <div>{{ toKhai.name }}</div>
        <feather-icon
          :class="{'show-template': toKhai.active}"
          icon="ChevronRightIcon"
          size="20"
        />
      </div>
      <b-collapse
        :id="toKhai.name"
        visible
      >
        <div
          v-for="(row, idx) in toKhai.listRow"
          :key="idx"
          :class="row.className"
        >
          <FormulateForm>
            <div class="row">
              <div
                v-for="(item, i) in row.listControl"
                :key="i"
                :class="item['wrap-class']"
              >
                <div
                  class="input-formulate"
                  :class="{ 'wrap-flex': item.horizontal} "
                >
                  <FormulateInput
                    v-model="item.value"
                    v-format-date
                    v-bind="item"
                    :name="item.name"
                    :width="item.horizontal ? item.groupPosition.input : null"
                    :show-value="true"
                    :item="item"
                    :placeholder="item.type === 'date' ? formatDate(item.value) : formatDateCheckTime(item.value)"
                    :class="{
                      'left-icon': item.iconLeft,
                      'right-icon': item.iconRight,
                    }"
                    :validation-rules="{
                      regex: ({ value }) => {
                        const regex = new RegExp(item.partternValue)
                        if(item.type === 'number') {
                          return regex.test(Number(value))
                        }
                        return regex.test(value)
                      }
                    }"
                    :disabled="true"
                    :wrapper-class="['muti-select']"
                  >
                    <template #label="{label}">
                      <div :style="{ width: item.horizontal ? `${item.groupPosition.label}%` : '100%', position: item.horizontal && item.iconLeft ? 'relative' : 'unset' }">
                        <!-- eslint-disable vue/no-v-html -->
                        <label
                          v-if="item.html"
                          v-html="item.html"
                        />
                        <!--eslint-enable-->
                        <label v-else-if="item.type !== 'customFile'">{{ label }}</label>
                        <span
                          v-if="item.horizontal && item.iconLeft"
                          class="icon-horizontal"
                          :class="{
                            'left': item.iconLeft,
                            'right': item.iconRight,
                          }"
                        >
                          <font-awesome-icon :icon="`fa-solid fa-${item.iconLeft}`" />
                        </span>
                      </div>
                    </template>
                  </FormulateInput>
                  <span
                    v-if="item.horizontal && item.iconRight"
                    class="input-icon-right"
                    :class="{'right': item.iconRight}"
                  >
                    <font-awesome-icon :icon="`fa-solid fa-${item.iconRight}`" />
                  </span>
                  <span
                    v-if="!item.horizontal && item.iconLeft"
                    class="input-icon"
                    :class="{'left': item.iconLeft}"
                  >
                    <font-awesome-icon :icon="`fa-solid fa-${item.iconLeft}`" />
                  </span>
                  <span
                    v-if="!item.horizontal && item.iconRight"
                    class="input-icon"
                    :class="{'right': item.iconRight}"
                  >
                    <font-awesome-icon :icon="`fa-solid fa-${item.iconRight}`" />
                  </span>
                </div>
              </div>
            </div>
          </FormulateForm>
        </div>
      </b-collapse>
    </div>
    <div class="d-sm-flex justify-content-end mt-2">
      <button
        class="compact ui btn-danger button"
        @click="closePage"
      >
        Đóng
      </button>
    </div>
  </div>
</template>
<script>
import END_POINTS from '@/api/endpoints'
import { VBToggle, BCollapse } from 'bootstrap-vue'
import { formatDate, formatDateRequest, formatDateCheckTime } from '@/utils'

export default {
  name: 'ThongTinToKhai',
  directives: {
    'b-toggle': VBToggle,
  },
  components: {
    BCollapse,
  },
  props: {
    idtthc: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      hoSoId: null,
      thongTinToKhai: {},
      danhSachToKhai: [],
      formKey: 0,
    }
  },
  created() {
    this.hoSoId = this.$route.query.id
    this.getThongTinToKhai(this.idtthc)
  },
  methods: {
    formatDate,
    formatDateRequest,
    formatDateCheckTime,
    async getThongTinToKhai(id) {
      await this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.LAY_TO_KHAI, { tthcId: id }).then(response => {
        if (response.data && response.data.succeeded) {
          this.danhSachToKhai = response.data.data.map(row => ({
            active: true,
            name: row.name,
            id: row.id,
            listRow: JSON.parse(row.content),
          }))
          this.chiTietToKhai(id)
        }
      })
    },
    closePage() {
      this.$emit('close-page')
    },
    async chiTietToKhai(id) {
      const payload = {
        id: this.hoSoId,
        tthcId: id,
      }
      await this.$axios.get(END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.CHI_TIET_BIEU_MAU, payload).then(response => {
        if (response.data && response.data.succeeded) {
          const result = response.data.data
          if (result) {
            result.forEach(bieuMau => {
              this.danhSachToKhai.forEach((toKhai, indToKhai) => {
                if (bieuMau.bieuMauId === toKhai.id) {
                  const keyControls = Object.keys(bieuMau.tableValue[0])
                  toKhai.listRow.forEach((row, indRow) => {
                    row.listControl.forEach((control, indControl) => {
                      if (keyControls.includes(control.code)) {
                        if (control.type === 'date') {
                          this.$set(this.danhSachToKhai[indToKhai].listRow[indRow].listControl[indControl], 'value', formatDateRequest(bieuMau.tableValue[0][control.code]))
                        } else {
                          this.$set(this.danhSachToKhai[indToKhai].listRow[indRow].listControl[indControl], 'value', bieuMau.tableValue[0][control.code])
                        }
                        this.formKey += 1
                      }
                    })
                  })
                }
              })
            })
          }
        }
      })
    },
  },
}
</script>
