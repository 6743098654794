<template>
  <div class="tab-common">
    <input
      id="tab1"
      v-model="conponentName"
      checked="checked"
      type="radio"
      name="tab-common"
      value="ChiTietHoSoTrucTuyen"
    >
    <input
      id="tab2"
      v-model="conponentName"
      type="radio"
      name="tab-common"
      value="ThongTinToKhai"
    >
    <nav>
      <ul>
        <li
          v-if="checkQuyenHoSoTrucTuyen"
          class="tab1"
        >
          <label for="tab1">THÔNG TIN HỒ SƠ</label>
        </li>
        <li class="tab2">
          <label for="tab2">THÔNG TIN TỜ KHAI
          </label>
        </li>
      </ul>
    </nav>
    <section>
      <div class="tab">
        <component
          :is="conponentName"
          :data="data"
          :rowhs="rowhs"
          :rowgt="rowgt"
          :rowgtk="rowgtk"
          :idtthc="cths.thuTucHanhChinhId"
          @close-page="closePage"
          @implement-ho-so="implementHoSo"
          @reject-ho-so="rejectHoSo"
          @accept-ho-so="acceptHoSo"
          @download-file="downloadFile"
        >
          <template
            slot="custom-form"
          >
            <div>
              <div class="d-flex align-items-center">
                <img
                  alt=""
                  src="@/assets/images/Vector.svg"
                >
                <h5 class="color-text">
                  Thông tin hồ sơ
                </h5>
              </div>
              <hr class="color-hr">
            </div>
            <div>
              <validation-observer
                ref="commonRules"
                class="custom-grid-xl-container mb-2"
              >
                <b-form-group
                  id="input-group-1"
                  label="Mã hồ sơ trực tuyến"
                  label-for="input-1"
                  class="grap-4"
                >
                  <b-form-input
                    id="input-1"
                    :value="cths.code"
                    type="text"
                    placeholder="Nhập mã hồ sơ trực tuyến"
                    :disabled="true"
                  />
                </b-form-group>
                <b-form-group
                  id="input-group-1"

                  label-for="input-1"
                  class="grap-4"
                />
                <b-form-group
                  id="input-group-1"

                  label-for="input-1"
                  class="grap-4"
                />
                <b-form-group
                  id="input-group-1"
                  label="Lĩnh vực"
                  label-for="input-1"
                  class="required grap-4"
                >
                  <treeselect
                    id="linhVuc"
                    v-model="cths.linhVucId"
                    v-format-v-select
                    :options="lvCbx"
                    placeholder="Chọn lĩnh vực"
                    :clear-on-select="true"
                    no-options-text="Không có dữ liệu"
                    no-results-text="Không có dữ liệu"
                    :normalizer="normalizer"
                    :match-keys="['label', 'label2']"
                    :disabled="true"
                  />
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Lệ phí(VNĐ)"
                  label-for="input-1"
                  class="grap-4"
                >
                  <b-form-input
                    id="input-1"
                    :value="cths.lePhi ? cths.lePhi.toString().replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ''"
                    type="text"
                    :placeholder="cths.lePhi ? 'Nhập lệ phí': ''"
                    required
                    :disabled="true"
                  />
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Phí HS(VNĐ)"
                  label-for="input-1"
                  class="grap-4"
                >
                  <b-form-input
                    id="input-1"
                    :value="cths.phiHs ? cths.phiHs.toString().replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ''"
                    type="text"
                    :placeholder="cths.phiHs ? 'Nhập phí hồ sơ' : ''"
                    required
                    :disabled="true"
                  />
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Tên thủ tục hành chính"
                  label-for="input-1"
                  class="required grap-12"
                >
                  <treeselect
                    id="linhVuc"
                    v-model="cths.thuTucHanhChinhId"
                    v-format-v-select
                    :options="tthcCbx"
                    placeholder="Chọn thủ tục hành chính"
                    :clear-on-select="true"
                    no-options-text="Không có dữ liệu"
                    no-results-text="Không có dữ liệu"
                    :normalizer="normalizer"
                    :match-keys="['label', 'label2']"
                    :disabled="true"
                  />
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Mẫu hồ sơ"
                  label-for="input-1"
                  class="required grap-12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    key="tenHanhDong"
                    :rules="{ required: true }"
                    name="Tên hành động"
                  >
                    <treeselect
                      id="linhVuc"
                      v-model="cths.mauHoSoId"
                      v-format-v-select
                      :options="mhsCbx"
                      placeholder="Chọn mẫu phiếu"
                      :clear-on-select="true"
                      no-options-text="Không có dữ liệu"
                      no-results-text="Không có dữ liệu"
                      :normalizer="normalizerMhs"
                      :match-keys="['label', 'label2']"
                      @input="changeMauHoSo"
                    />
                    <span class="label-noti-validate">{{ errors[0] }}</span>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Ngày nộp"
                  label-for="input-1"
                  class="grap-4"
                >
                  <date-picker
                    v-model="cths.ngayNop"
                    type="datetime"
                    format="DD/MM/YYYY HH:mm"
                    class="h-100 w-100"
                    :disabled="true"
                  />
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Ngày tiếp nhận"
                  label-for="input-1"
                  class="grap-4"
                >
                  <date-picker
                    v-model="cths.ngayTiepNhan"
                    type="datetime"
                    format="DD/MM/YYYY HH:mm"
                    class="h-100 w-100"
                    :disabled="true"
                  />
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Ngày hẹn trả KQ"
                  label-for="input-1"
                  class="grap-4"
                >
                  <date-picker
                    v-model="cths.ngayHenTraKq"
                    format="DD/MM/YYYY HH:mm"
                    class="h-100 w-100"
                    :disabled="true"
                  />
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Giờ hẹn trả từ"
                  label-for="input-1"
                  class="grap-4"
                >
                  <date-picker
                    v-model="cths.gioHenTraTu"
                    class="w-100 date-picker"
                    type="time"
                    format="HH:mm"
                    :disabled-time="disableTimeTraTu"
                  />
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Giờ hẹn trả đến"
                  label-for="input-1"
                  class="grap-4"
                >
                  <date-picker
                    v-model="cths.gioHenTraDen"
                    class="w-100 date-picker"
                    type="time"
                    format="HH:mm"
                    :disabled-time="disableTimeTraDen"
                  />
                </b-form-group>
              </validation-observer>
            </div>
          </template>
        </component>
      </div>
    </section>
    <common-modal
      ref="commomnModal"
      :title="title"
      :size="size"
      :content="contentModal"
      @handle-ok="handleOk"
    >
      <component
        :is="componentNameModal"
        :data-form="dataForm"
        @delete-ho-so="deleteHoso"
      />
    </common-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { faTag } from '@fortawesome/free-solid-svg-icons'
import END_POINTS from '@/api/endpoints'
import {
  BCard,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import ChiTietHoSoTrucTuyen from '@/components/ChiTietHoSoTrucTuyen.vue'
import { ROUTE_NAME } from '@/modules/tiep-nhan-ho-so/constants/constants'
import commonModal from '@/modules/tiep-nhan-ho-so/components/modal/CommonModal.vue'
import LyDoTuChoHoSo from '@/modules/tiep-nhan-ho-so/components/form/LyDoTuChoHoSo.vue'
import YeuCauBoSung from '@/modules/tiep-nhan-ho-so/components/form/YeuCauBoSung.vue'
import ThongTinToKhai from '@/modules/tiep-nhan-ho-so/components/form/ThongTinToKhai.vue'
import { removeDiacritical } from '@/utils/index'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import GoodTable from '@/components/GoodTable.vue'
import * as dayjs from 'dayjs'
import { PERMISSION_NAME } from '@/constants/constants'
import { hasPermission } from '@/utils/permission-utils'

export default {
  name: 'ChiTietTiepNhan',
  components: {
    ChiTietHoSoTrucTuyen,
    BCard,
    commonModal,
    LyDoTuChoHoSo,
    YeuCauBoSung,
    ThongTinToKhai,
    BFormInput,
    BFormGroup,
    DatePicker,
    GoodTable,
  },
  data() {
    return {
      hoSoId: null,
      timeTiepNhan: new Date(),
      faTag,
      data: null,
      conponentName: 'ChiTietHoSoTrucTuyen',
      title: '',
      size: '',
      componentNameModal: '',
      contentModal: null,
      dataForm: null,
      lvCbx: [],
      tthcCbx: [],
      mhsCbx: [],
      cths: {
        linhVucId: null,
      },
      rowhs: [],
      rowgt: [],
      rowgtk: [],
      titleOk: null,
      permissionName: null,
      permissionType: [
        {
          name: 'HSTNTRUC_TUYEN',
          type: 'truc-tiep',
        },
        {
          name: 'HSTNTRUC_TUYEN_BO_SUNG',
          type: 'bo-sung',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      permission: 'common/permissions',
    }),
    checkQuyenHoSoTrucTuyen() {
      return hasPermission([PERMISSION_NAME[this.permissionName].CHI_TIET_THONG_TIN_CHUNG])
    },
  },
  created() {
    this.hoSoId = this.$route.query.id
    const result = this.permissionType.find(item => item.type === this.$route.query.type)
    this.permissionName = result ? result.name : null
    if (this.checkQuyenHoSoTrucTuyen) {
      this.conponentName = 'ChiTietHoSoTrucTuyen'
    } else {
      this.$axios.get(`${END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.CHI_TIET_CHO_TIEP_NHAN}?id=${this.hoSoId}`).then(res => {
        if (res.data?.succeeded) {
          this.cths = res.data.data
          this.conponentName = 'ThongTinToKhai'
        }
      })
      return
    }
    Promise.all([this.$axios.get(END_POINTS.LINH_VUC.COMBOBOX), this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.COMBOBOX), this.$axios.get(`${END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.CHI_TIET_CHO_TIEP_NHAN}?id=${this.hoSoId}`)]).then(res => {
      if (res[0].data?.succeeded) {
        this.lvCbx = res[0].data.data
      }
      if (res[1].data?.succeeded) {
        this.tthcCbx = res[1].data.data
      }
      if (res[2].data?.succeeded) {
        this.cths = res[2].data.data
        this.cths.mauHoSoId = null
        this.cths.ngayNop = res[2].data.data.ngayNop ? new Date(res[2].data.data.ngayNop) : new Date()
        this.cths.ngayTiepNhan = res[2].data.data.ngayTiepNhan ? new Date(res[2].data.data.ngayTiepNhan) : new Date()
      }
      this.$axios.get(`${END_POINTS.THU_TUC_HANH_CHINH.MAU_HO_SO}?tthcId=${this.cths.thuTucHanhChinhId}`).then(ress => {
        if (ress.data?.succeeded) {
          this.mhsCbx = ress.data.data
          if (this.mhsCbx && this.mhsCbx.length === 1) {
            this.cths.mauHoSoId = this.mhsCbx[0].id
          }
        }
        this.data = {
          code: this.cths.code,
          ngayNop: this.cths.isTrucTuyen ? this.formatDate(this.cths.ngayNop) : this.formatDate(this.cths.ngayTiepNhan),
          ngayTiepNhan: this.cths.ngayTiepNhan ? this.formatDate(this.cths.ngayTiepNhan) : null,
          nguoiNop_HoVaTen: this.cths.nguoiNop_HoVaTen,
          chuHoSo_HoVaTen: this.cths.chuHoSo_HoVaTen,
          thoiGianDenHanTraKQ: this.cths?.gioHenTraTu && this.cths?.gioHenTraDen ? this.convertMinsToHrsMins(this.differenceInMinutes(this.cths.gioHenTraTu, this.cths.gioHenTraDen)) : 0,
          nguoiNop_DiaChiDayDu: this.cths.nguoiNop_DiaChiDayDu,
          chuHoSo_DiaChiDayDu: this.cths.chuHoSo_DiaChiDayDu,
          chuHoSo_DiaChiEmail: this.cths.chuHoSo_DiaChiEmail,
          nguoiNop_DiaChiEmail: this.cths.nguoiNop_DiaChiEmail,
          chuHoSo_SoDienThoai: this.cths.chuHoSo_SoDienThoai,
          nguoiNop_SoDienThoai: this.cths.nguoiNop_SoDienThoai,
          chuHoSo_CMND_HoChieu: this.cths.chuHoSo_CMND_HoChieu,
          nguoiNop_CMND_HoChieu: this.cths.nguoiNop_CMND_HoChieu,
        }
        this.rowhs = this.cths.hoSoGiayToModels || []
        this.rowgt = this.cths.hoSoGiayToKhacModels || []
        this.rowgtk = this.cths.hoSo_GiayToBoSungModels || []
        // this.cths.ngayNop = new Date(res.data.data.ngayNop) || new Date()
        // this.cths.ngayTiepNhan = new Date(res.data.data.ngayTiepNhan) || new Date()
      })
    })
  },
  methods: {
    normalizer(node) {
      return {
        id: node.id,
        label: node.name,
        label2: removeDiacritical(node.name),
      }
    },
    normalizerMhs(node) {
      return {
        id: node.id,
        label: node.tenMauHoSo,
        label2: removeDiacritical(node.tenMauHoSo),
      }
    },
    downloadFile(data) {
      this.$axios.post(END_POINTS.UPLOAD_FILE.DOWNLOAD, { path: data.path }).then(res => {
        if (res.data?.succeeded) {
          // eslint-disable-next-line prefer-const
          let a = document.createElement('a')
          a.href = `data:application/octet-stream;base64,${res.data.fileData}`
          a.download = res.data.fileName
          a.setAttribute('target', '_blank')
          a.click()
        }
      })
    },
    disableTimeTraTu(date) {
      if (this.cths.gioHenTraDen) {
        return date > this.cths.gioHenTraDen
      }
      return null
    },
    disableTimeTraDen(date) {
      if (this.cths.gioHenTraTu) {
        return date < this.cths.gioHenTraTu
      }
      return null
    },
    formatDate(date) {
      return date ? dayjs(date).format('HH:mm - DD/MM/YYYY') : null
    },
    differenceInMinutes(start, end) {
      const totalMinutes = value => {
        const match = (/(\d{1,2}):(\d{1,2})/g).exec(value)
        return (Number(match[1]) * 60) + Number(match[2])
      }
      return totalMinutes(end) - totalMinutes(start)
    },
    convertMinsToHrsMins(minutes) {
      let h = Math.floor(minutes / 60)
      let m = minutes % 60
      h = h < 10 ? `0${h}` : h
      m = m < 10 ? `0${m}` : m
      return `${h}:${m}`
    },
    getDataMauHoSo(idtthc) {
      this.$axios.get(`${END_POINTS.THU_TUC_HANH_CHINH.MAU_HO_SO}?id=${idtthc}`).then(res => {
        if (res.data?.succeeded) {
          this.mhsCbx = res.data.data
        }
      })
    },
    closePage() {
      this.$router.push({ path: ROUTE_NAME.HS_TRUC_TUYEN.DS })
    },
    rejectHoSo() {
      this.title = 'lý do từ chối tiếp nhận hồ sơ'
      this.componentNameModal = 'LyDoTuChoHoSo'
      this.size = 'lg'
      this.dataForm = {
        title: 'lý do từ chối tiếp nhận hồ sơ',
        lyDoTuChoi: null,
      }
      this.titleOk = 'Đồng ý'
      this.$refs.commomnModal.showModal()
    },
    acceptHoSo() {
      this.$refs.commonRules.validate().then(success => {
        if (success) {
          this.title = 'xác nhận'
          this.componentNameModal = null
          this.contentModal = 'Bạn có chắc chắn tiếp nhận hồ sơ?'
          this.size = 'md'
          this.titleOk = null
          this.$refs.commomnModal.showModal()
        }
      })
    },
    handleOk() {
      if (this.componentNameModal === 'LyDoTuChoHoSo') {
        const payload = {
          dsTuChoiTiepNhanHoSo: [
            {
              id: this.cths.id,
              lyDoTuChoi: this.dataForm.lyDoTuChoi,
            },
          ],
        }
        this.$axios.post(END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.TU_CHOI, payload).then(res => {
          if (res.data?.succeeded) {
            this.closePage()
          }
        })
      } else if (this.componentNameModal === 'YeuCauBoSung') {
        const payload = {
          hoSoId: this.hoSoId,
          hoSo_GiayToBoSungModels: this.dataForm,
        }
        this.$axios.post(`${END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.BO_SUNG}`, payload).then(res => {
          if (res.data?.succeeded) {
            this.$router.push({ path: '/tiep-nhan-ho-so/hs-truc-tuyen/tiep-nhan-ho-so-truc-tuyen', query: { name: 'trucTiepBoSung' } })
          }
        })
      } else {
        const payload = {
          dsTiepNhanHoSo: [
            {
              id: this.cths.id,
              mauHoSoId: this.cths.mauHoSoId,
              thongTinMota: this.cths.thongTinMota,
              gioHenTraTu: this.cths.gioHenTraTu,
              gioHenTraDen: this.cths.gioHenTraDen,
              ngayHenTraKq: this.cths.ngayHenTraKq,
              ngayNop: this.cths.ngayNop,
              ngayTiepNhan: this.cths.ngayTiepNhan,
              phi: this.cths.phiHs,
              lePhi: this.cths.lePhi,
            },
          ],
        }
        this.$axios.post(END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.TIEP_NHAN, payload).then(res => {
          if (res.data?.succeeded) {
            this.$router.push({ path: '/xu-ly-ho-so/ban-giao-hs/ban-giao-hs' })
          }
          if (res.data?.data) {
            res.data.data.forEach(element => {
              if (!element.succeeded) {
                this.$toast.error(element.message, { timeout: 3000 })
              }
            })
          }
        })
      }
    },
    implementHoSo() {
      this.title = 'Yêu cầu bổ sung'
      this.dataForm = [{
        tenGiayTo: null,
        banChinh: 0,
        banSao: 0,
        banPhoto: 0,
        dsHoSoGiayToFileDinhKem: [],
      }]
      this.titleOk = null
      this.size = 'xl'
      this.componentNameModal = 'YeuCauBoSung'
      this.$refs.commomnModal.showModal()
    },
    deleteHoso(index) {
      this.dataForm = this.dataForm.filter((item, idx) => idx !== index)
    },
    changeMauHoSo() {
      const payload = {
        mauHoSoId: this.cths.mauHoSoId,
        tthcId: this.cths.thuTucHanhChinhId,
      }
      this.$axios.post(END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.LAY_HAN_TRA_KET_QUA, payload, false).then(res => {
        if (res.data?.succeeded) {
          // this.cths.ngayHenTraKq = new Date(res.data.data.ngayNop)
          this.cths.ngayHenTraKq = new Date(res.data.data.ngayHenTraKq)
        }
      })
    },
  },
}
</script>
<style scoped lang="scss">
.tab-common > input,
.tab-common section > div {
  display: none;
}

#tab1:checked ~ section .tab,
#tab2:checked ~ section .tab,
#tab3:checked ~ section .tab {
  display: block;
}

$activeColor: #005DB4;
$unactiveColor: #eeeeee;
$unactiveHoverColor: #dddddd;

.tab-common {
  width: 100%;
  margin: 0 auto;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      label {
        float: left;
        padding: 0.5rem 1.5rem;
        border: 1px solid #ddd;
        border-bottom: 0;
        background: #ffffff;
        color: #444;
        margin-bottom: 0 !important;
        &:hover {
          background: $unactiveHoverColor;
          cursor: pointer;
        }
        &:active {
          background: $activeColor;
        }
      }
      &:not(:last-child) label {
         border-right-width: 0;
      }
    }
  }
  section {
    clear: both;
    div {
      width: 100%;
      background: #fff;
      line-height: 1.5em;
      letter-spacing: 0.3px;
      color: #444;
      h2 {
        margin: 0;
        letter-spacing: 1px;
        color: #34495e;
      }
    }
  }
}

#tab1:checked ~ nav .tab1,
#tab2:checked ~ nav .tab2,
#tab3:checked ~ nav .tab3 {
  label {
    background: $activeColor;
    color: #fff;
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: $activeColor;
      left: 0;
      bottom: -1px;
    }
  }
}
.color-hr{
    border-top: 1px solid #005DB4 !important;
  }
  .color-text{
    color: #005DB4 !important;
    margin-left: 12px;
    font-size: 16px;
  }
  h5{ margin-bottom: 0rem !important;}
</style>
