<template>
  <div>
    <b-form-group
      id="input-group-1"
      label="Thành phần yêu cầu bổ sung"
      label-for="textarea"
      class="required"
    >
      <vue-good-table
        :columns="columns"
        :rows="dataForm"
      >
        <div
          slot="emptystate"
          class="text-center"
        >
          Không có dữ liệu
        </div>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field == 'tenGiayTo'">
            <validation-provider
              v-slot="{ errors }"
              :key="props.index"
              :rules="{ required: true }"
              :name="`giayTo_${props.index}`"
            >
              <b-form-input
                id="input-1"
                :ref="`giayTo_${props.index}`"
                v-model="dataForm[props.index].tenGiayTo"
                type="text"
                placeholder="Nhập tên giấy tờ"
              />
              <span class="label-noti-validate">{{ errors[0] }}</span>
            </validation-provider>
          </span>
          <span v-if="props.column.field == 'banChinh'">
            <validation-provider
              v-slot="{ errors }"
              :key="props.index"
              :rules="{ required: true }"
              :name="`banChinh_${props.index}`"
            >
              <b-form-input
                id="input-1"
                :ref="`banChinh_${props.index}`"
                v-model="dataForm[props.index].banChinh"
                v-block-e
                v-format-positive-integers
                v-is-int
                type="number"
                placeholder="Nhập bản chính"
              />
              <span class="label-noti-validate">{{ errors[0] }}</span>
            </validation-provider>
          </span>
          <span v-if="props.column.field == 'banSao'">
            <validation-provider
              v-slot="{ errors }"
              :key="props.index"
              :rules="{ required: true }"
              :name="`banSao_${props.index}`"
            >
              <b-form-input
                id="input-1"
                :ref="`banSao_${props.index}`"
                v-model="dataForm[props.index].banSao"
                v-block-e
                v-format-positive-integers
                v-is-int
                type="number"
                placeholder="Nhập bản sao"
              />
              <span class="label-noti-validate">{{ errors[0] }}</span>
            </validation-provider>
          </span>
          <span v-if="props.column.field == 'banPhoto'">
            <validation-provider
              v-slot="{ errors }"
              :key="props.index"
              :rules="{ required: true }"
              :name="`banPhoTo_${props.index}`"
            >
              <b-form-input
                id="input-1"
                :ref="`banPhoTo_${props.index}`"
                v-model="dataForm[props.index].banPhoto"
                v-block-e
                v-format-positive-integers
                v-is-int
                type="number"
                placeholder="Nhập bản photo"
              />
              <span class="label-noti-validate">{{ errors[0] }}</span>
            </validation-provider>
          </span>
          <span v-else-if="props.column.field == 'actions'">
            <div class="d-flex justify-content-center">
              <plus-square-icon
                v-if="props.index + 1 == dataForm.length"
                v-b-tooltip.hover.v-secondary
                title="Thêm"
                size="16"
                class="custom-class cursor-pointer mr-1"
                @click="e => dataForm.push({ tenGiayTo: null, banChinh: 0, banSao: 0, banPhoto: 0, dsHoSoGiayToFileDinhKem: [] })"
              />
              <trash-2-icon
                v-if="dataForm.length > 1"
                v-b-tooltip.hover.v-secondary
                title="Xoá"
                size="16"
                class="custom-class cursor-pointer"
                @click="deleteHoSo(props.index)"
              />
            </div>
          </span>
          <span v-else-if="props.column.field == 'STT'">
            {{ rowNumber(props) }}
          </span>
        </template>
      </vue-good-table>
    </b-form-group>
  </div>
</template>
<script>
import {
  BFormInput,
  VBTooltip,
  BFormGroup,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { PlusSquareIcon, Trash2Icon } from 'vue-feather-icons'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormInput,
    BFormGroup,
    VueGoodTable,
    PlusSquareIcon,
    Trash2Icon,
  },
  props: {
    dataForm: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center vertical-align-middle',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Tên giấy tờ',
          field: 'tenGiayTo',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle',
        },
        {
          label: 'Bản chính',
          field: 'banChinh',
          width: '200px',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle',
        },
        {
          label: 'Bản sao',
          field: 'banSao',
          width: '200px',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle',
        },
        {
          label: 'Bản photo',
          field: 'banPhoto',
          width: '200px',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle',
        },
        {
          label: 'Thao tác',
          field: 'actions',
          width: '150px',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle',
        },
      ],
      rows: [],
    }
  },
  methods: {
    rowNumber(row) {
      return row.index + 1
    },
    deleteHoSo(index) {
      this.$emit('delete-ho-so', index)
    },
  },
}
</script>
