<template>
  <chi-tiet-tiep-nhan />
</template>
<script>
import ChiTietTiepNhan from '@/modules/tiep-nhan-ho-so/components/pages/tiep-nhan-truc-tuyen/ChiTietTiepNhan.vue'

export default {
  components: {
    ChiTietTiepNhan,
  },
}
</script>
