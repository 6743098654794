var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',{staticClass:"required",attrs:{"id":"input-group-1","label":"Thành phần yêu cầu bổ sung","label-for":"textarea"}},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.dataForm},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'tenGiayTo')?_c('span',[_c('validation-provider',{key:props.index,attrs:{"rules":{ required: true },"name":("giayTo_" + (props.index))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:("giayTo_" + (props.index)),attrs:{"id":"input-1","type":"text","placeholder":"Nhập tên giấy tờ"},model:{value:(_vm.dataForm[props.index].tenGiayTo),callback:function ($$v) {_vm.$set(_vm.dataForm[props.index], "tenGiayTo", $$v)},expression:"dataForm[props.index].tenGiayTo"}}),_c('span',{staticClass:"label-noti-validate"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(props.column.field == 'banChinh')?_c('span',[_c('validation-provider',{key:props.index,attrs:{"rules":{ required: true },"name":("banChinh_" + (props.index))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"block-e",rawName:"v-block-e"},{name:"format-positive-integers",rawName:"v-format-positive-integers"},{name:"is-int",rawName:"v-is-int"}],ref:("banChinh_" + (props.index)),attrs:{"id":"input-1","type":"number","placeholder":"Nhập bản chính"},model:{value:(_vm.dataForm[props.index].banChinh),callback:function ($$v) {_vm.$set(_vm.dataForm[props.index], "banChinh", $$v)},expression:"dataForm[props.index].banChinh"}}),_c('span',{staticClass:"label-noti-validate"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(props.column.field == 'banSao')?_c('span',[_c('validation-provider',{key:props.index,attrs:{"rules":{ required: true },"name":("banSao_" + (props.index))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"block-e",rawName:"v-block-e"},{name:"format-positive-integers",rawName:"v-format-positive-integers"},{name:"is-int",rawName:"v-is-int"}],ref:("banSao_" + (props.index)),attrs:{"id":"input-1","type":"number","placeholder":"Nhập bản sao"},model:{value:(_vm.dataForm[props.index].banSao),callback:function ($$v) {_vm.$set(_vm.dataForm[props.index], "banSao", $$v)},expression:"dataForm[props.index].banSao"}}),_c('span',{staticClass:"label-noti-validate"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(props.column.field == 'banPhoto')?_c('span',[_c('validation-provider',{key:props.index,attrs:{"rules":{ required: true },"name":("banPhoTo_" + (props.index))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"block-e",rawName:"v-block-e"},{name:"format-positive-integers",rawName:"v-format-positive-integers"},{name:"is-int",rawName:"v-is-int"}],ref:("banPhoTo_" + (props.index)),attrs:{"id":"input-1","type":"number","placeholder":"Nhập bản photo"},model:{value:(_vm.dataForm[props.index].banPhoto),callback:function ($$v) {_vm.$set(_vm.dataForm[props.index], "banPhoto", $$v)},expression:"dataForm[props.index].banPhoto"}}),_c('span',{staticClass:"label-noti-validate"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):(props.column.field == 'actions')?_c('span',[_c('div',{staticClass:"d-flex justify-content-center"},[(props.index + 1 == _vm.dataForm.length)?_c('plus-square-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-secondary",modifiers:{"hover":true,"v-secondary":true}}],staticClass:"custom-class cursor-pointer mr-1",attrs:{"title":"Thêm","size":"16"},on:{"click":function (e) { return _vm.dataForm.push({ tenGiayTo: null, banChinh: 0, banSao: 0, banPhoto: 0, dsHoSoGiayToFileDinhKem: [] }); }}}):_vm._e(),(_vm.dataForm.length > 1)?_c('trash-2-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-secondary",modifiers:{"hover":true,"v-secondary":true}}],staticClass:"custom-class cursor-pointer",attrs:{"title":"Xoá","size":"16"},on:{"click":function($event){return _vm.deleteHoSo(props.index)}}}):_vm._e()],1)]):(props.column.field == 'STT')?_c('span',[_vm._v(" "+_vm._s(_vm.rowNumber(props))+" ")]):_vm._e()]}}])},[_c('div',{staticClass:"text-center",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" Không có dữ liệu ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }