<template>
  <div class="card-body">
    <slot
      name="custom-form"
      class="mb-1"
    />
    <div>
      <div class="d-flex align-items-center">
        <img
          alt=""
          src="../assets/images/Vector.svg"
        >
        <h5 class="color-text">
          Thông tin người nộp hồ sơ
        </h5>
      </div>
      <hr class="color-hr">
    </div>
    <div class="custom-grid-xl-container mb-1">
      <div
        v-for="item in listTTHS"
        :key="item.id"
        :class="item.class"
        class="mb-1"
      >
        <span v-if="item && item.name">{{ item.name }}:</span> {{ data && data[item.field] }}
      </div>
    </div>
    <div>
      <div class="d-flex align-items-center">
        <img
          alt=""
          src="../assets/images/Vector.svg"
        >
        <h5 class="color-text">
          Thông tin thành phần hồ sơ
        </h5>
      </div>
      <hr class="color-hr">
    </div>

    <div v-if="rowhs.length > 0">
      <good-table
        ref="vgTable"
        class="mb-3"
        :columns="columns"
        :rows="rowhs"
        :is-loading="false"
        :pagination="false"
        :selectable="false"
      >
        <template
          slot="custom-row"
          slot-scope="props"
        >
          <span v-if="props.props.column.field == 'dsHoSoGiayToFileDinhKem'">
            <div
              v-for="(item, index) in props.props.row.dsHoSoGiayToFileDinhKem"
              :key="`${item.tenFileDinhKem}_${index}_ghs`"
            >
              <a
                href="javascript:;"
                @click="downloadFileDVC(item)"
              >- {{ item.tenFileDinhKem }}</a>
            </div>
          </span>
        </template>
      </good-table>
    </div>
    <div
      v-else
      class="mb-1"
    >
      Không có
    </div>
    <div>
      <div class="d-flex align-items-center">
        <img
          alt=""
          src="../assets/images/Vector.svg"
        >
        <h5 class="color-text">
          Thông tin giấy tờ khác
        </h5>
      </div>
      <hr class="color-hr">
    </div>
    <div
      v-if="rowgt.length > 0"
      class="mb-1"
    >
      <good-table
        ref="vgTable"
        class="mb-3"
        :columns="columnsGTK"
        :rows="rowgt"
        :is-loading="false"
        :pagination="false"
        :selectable="false"
      >
        <template
          slot="custom-row"
          slot-scope="props"
        >
          <span v-if="props.props.column.field == 'dsHoSoGiayToFileDinhKem'">
            <div
              v-for="(item, index) in props.props.row.dsHoSoGiayToFileDinhKem"
              :key="`${item.tenFileDinhKem}_${index}_ggt`"
            >
              <a
                href="javascript:;"
                @click="downloadFileDVC(item)"
              >- {{ item.tenFileDinhKem }}</a>
            </div>
          </span>
        </template>
      </good-table>
    </div>
    <div
      v-else
      class="mb-1"
    >
      Không có
    </div>
    <div>
      <div class="d-flex align-items-center">
        <img
          alt=""
          src="../assets/images/Vector.svg"
        >
        <h5 class="color-text">
          Thông tin giấy tờ tiếp nhận bổ sung
        </h5>
      </div>
      <hr class="color-hr">
    </div>
    <div
      v-if="rowgtk.length > 0"
      class="mb-1"
    >
      <good-table
        ref="vgTable"
        class="mb-3"
        :columns="columns"
        :rows="giayToBoSung"
        :is-loading="false"
        :pagination="false"
        :selectable="false"
      >
        <template
          slot="custom-row"
          slot-scope="props"
        >
          <span v-if="props.props.column.field == 'dsHoSoGiayToFileDinhKem'">
            <div
              v-for="(item, index) in props.props.row.dsHoSoGiayToFileDinhKem"
              :key="`${item.tenFileDinhKem}_${index}_ggtk`"
            >
              <a
                href="javascript:;"
                @click="downloadFileDVC(item)"
              >- {{ item.tenFileDinhKem }}</a>
            </div>
          </span>
        </template>
      </good-table>
    </div>
    <div
      v-else
      class="mb-1"
    >
      Không có
    </div>
    <div class="d-sm-flex justify-content-end">
      <button
        v-if="checkQuyenTiepNhan"
        class="compact ui btn-primary button mr-1"
        @click="acceptHoSo"
      >
        Tiếp nhận
      </button>
      <button
        v-if="checkQuyenYeuCauBoSung"
        class="compact ui btn-primary button mr-1"
        @click="implementHoSo"
      >
        Yêu cầu bổ sung
      </button>
      <button
        v-if="checkQuyenTuChoiTiepNhanHoSo"
        class="compact ui btn-primary button mr-1"
        @click="rejectHoSo"
      >
        Từ chối tiếp nhận hồ sơ
      </button>
      <button
        class="compact ui btn-danger button"
        @click="closePage"
      >
        Đóng
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import addCssSemantic from '@/mixins/mixins'
import GoodTable from '@/components/GoodTable.vue'
import { PERMISSION_NAME } from '@/constants/constants'
import { hasPermission } from '@/utils/permission-utils'
import END_POINTS from '@/api/endpoints'

export default {
  name: 'ChiTietHoSo',
  components: {
    GoodTable,
  },
  mixins: [addCssSemantic],
  props: {
    data: {
      type: Object,
      default: null,
    },
    rowhs: {
      type: Array,
      default: () => [],
    },
    rowgt: {
      type: Array,
      default: () => [],
    },
    rowgtk: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      listTTHS: [
        {
          id: 0,
          name: 'Người nộp',
          field: 'nguoiNop_HoVaTen',
          class: 'grap-4',
        },
        {
          id: 1,
          name: 'Chủ hồ sơ',
          field: 'chuHoSo_HoVaTen',
          class: 'grap-4',
        },
        {
          id: 2,
          name: '',
          field: '',
          class: 'grap-4',
        },
        {
          id: 3,
          name: 'Số CMND/Hộ chiếu',
          field: 'nguoiNop_CMND_HoChieu',
          class: 'grap-4',
        },
        {
          id: 4,
          name: 'Số CMND/Hộ chiếu',
          field: 'chuHoSo_CMND_HoChieu',
          class: 'grap-4',
        },
        {
          id: 5,
          name: '',
          field: '',
          class: 'grap-4 d-none d-lg-block',
        },
        {
          id: 6,
          name: 'Địa chỉ',
          field: 'nguoiNop_DiaChiDayDu',
          class: 'grap-4 word-break',
        },
        {
          id: 7,
          name: 'Địa chỉ',
          field: 'chuHoSo_DiaChiDayDu',
          class: 'grap-4 word-break',
        },
        {
          id: 8,
          name: '',
          field: '',
          class: 'grap-4 d-none d-lg-block',
        },
        {
          id: 9,
          name: 'Email',
          field: 'nguoiNop_DiaChiEmail',
          class: 'grap-4',
        },
        {
          id: 10,
          name: 'Email',
          field: 'chuHoSo_DiaChiEmail',
          class: 'grap-4',
        },
        {
          id: 11,
          name: '',
          field: '',
          class: 'grap-4 d-none d-lg-block',
        },
        {
          id: 12,
          name: 'Điện thoại',
          field: 'nguoiNop_SoDienThoai',
          class: 'grap-4',
        },
        {
          id: 13,
          name: 'Điện thoại',
          field: 'chuHoSo_SoDienThoai',
          class: 'grap-4',
        },
      ],
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Tên giấy tờ',
          field: 'tenGiayTo',
          thClass: 'text-center',
          sortable: false,
        },
        {
          label: 'Bản chính',
          field: 'banChinh',
          width: '150px',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          label: 'Bản sao',
          field: 'banSao',
          tdClass: 'text-center',
          thClass: 'text-center',
          width: '150px',
          sortable: false,
        },
        {
          label: 'Bản photo',
          field: 'banPhoto',
          tdClass: 'text-center',
          thClass: 'text-center',
          width: '150px',
          sortable: false,
        },
        {
          label: 'File đính kèm',
          field: 'dsHoSoGiayToFileDinhKem',
          thClass: 'text-center',
          width: '300px',
          sortable: false,
        },
      ],
      columnsGTK: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Tên giấy tờ',
          field: 'tenGiayTo',
          width: '300px',
          thClass: 'text-center',
          sortable: false,
        },
        {
          label: 'File đính kèm',
          field: 'dsHoSoGiayToFileDinhKem',
          thClass: 'text-center',
          sortable: false,
        },
      ],
      permissionName: null,
      permissionType: [
        {
          name: 'HSTNTRUC_TUYEN',
          type: 'truc-tiep',
        },
        {
          name: 'HSTNTRUC_TUYEN_BO_SUNG',
          type: 'bo-sung',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      permission: 'common/permissions',
    }),
    checkQuyenThongTinHoSo() {
      return hasPermission([PERMISSION_NAME[this.permissionName].CHI_TIET_THONG_TIN_CHUNG])
    },
    checkQuyenTiepNhan() {
      return hasPermission([PERMISSION_NAME[this.permissionName].TIEP_NHAN])
    },
    checkQuyenYeuCauBoSung() {
      return hasPermission([PERMISSION_NAME[this.permissionName].YEU_CAU_BO_SUNG])
    },
    checkQuyenTuChoiTiepNhanHoSo() {
      return hasPermission([PERMISSION_NAME[this.permissionName].TU_CHOI])
    },
    giayToBoSung() {
      return this.sortArray(this.rowgtk, 'thuTu')
    },
  },
  created() {
    const result = this.permissionType.find(item => item.type === this.$route.query.type)
    this.permissionName = result ? result.name : null
  },
  methods: {
    sortArray(arr, key) {
      return arr.sort((a, b) => {
        if (a[key] < b[key]) return -1
        if (a[key] > b[key]) return 1
        return 0
      })
    },
    acceptHoSo() {
      this.$emit('accept-ho-so')
    },
    closePage() {
      this.$emit('close-page')
    },
    implementHoSo() {
      this.$emit('implement-ho-so')
    },
    rejectHoSo() {
      this.$emit('reject-ho-so')
    },
    downloadFileDVC(item) {
      this.$axios.post(`${END_POINTS.UPLOAD_FILE.DOWNLOAD_DVC}?id=${item.id}`, null, false).then(res => {
        if (res.data.data?.succeeded) {
          const a = document.createElement('a')
          a.href = `data:application/octet-stream;base64,${res.data.data.fileData}`
          a.download = res.data.data.fileName
          a.setAttribute('target', '_blank')
          a.click()
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
  .color-hr{
    border-top: 1px solid #005DB4 !important;
  }
  .color-text{
    color: #005DB4 !important;
    margin-left: 12px;
    font-size: 16px;
  }
  h5{ margin-bottom: 0rem !important;}
  img {
    width: 18px;
    margin-bottom: 4px;
  }
</style>
