<template>
  <div>
    <b-form-group
      id="input-group-1"
      label="Lý do từ chối tiếp nhận hồ sơ"
      label-for="textarea"
      class="required"
    >
      <validation-provider
        v-slot="{ errors }"
        key="tieuDeCauHoi"
        :rules="{ required: true }"
        name="Tiêu đề câu hỏi"
      >
        <b-form-textarea
          id="textarea"
          v-model="dataForm.lyDoTuChoi"
          type="text"
          :placeholder="`Nhập ${dataForm.title}`"
          rows="3"
          max-rows="6"
        />
        <span class="label-noti-validate">{{ errors[0] }}</span>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
import {
  BFormTextarea,
  BFormGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BFormTextarea,
    BFormGroup,
  },
  props: {
    dataForm: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
}
</script>
